/* eslint-disable no-console */
export default {
  name: 'Assistance618',
  data() {
    return {
      expireTime: '2020-06-18 24: 00',
      isLYBVaild: '',
      hour: 0,
      minute: 0,
      second: 0,
      miSecond: 0,
      maskShow: false,
      activity: '',
      lotteryShow: false,
      prizeIsPopUp: true,
      userLotteryId: '', //助力活动的id
      loginToken: '', //登录token
      prizeId: '',
      prizeList: [],
      curIndex: null,
      isTakeIn: false, //是否参加了活动
      activityId: '',
      myLotteryData: {
        choosePrize: {}
      },
      guideShow: false,
      progressRemind: '',
      overTimeRemind: '',
      endDay: '',
      beginDay: '',
      leftTime: '',
      openid: '',
      avatar: '',
      btx: '邀请好友助力抽奖',
      answerTxt: '抽奖',
      done: '',
      cHegiht: '',
      gzhAttentionShow: false,
      shareShow: false,
      nickname: ''
    }
  },
  methods: {
    countTime() {
      let nowTimeStr = new Date().getTime()
      // let endTimeStr = new Date(this.expireTime).getTime()
      let endTimeStr = new Date(this.myLotteryData.expireTime.replace(/-/g, '/')).getTime()
      let leftTimeStr = endTimeStr - nowTimeStr;
      this.leftTime = leftTimeStr
      if (leftTimeStr > 0) {
        this.hour = Math.floor(leftTimeStr / 1000 / 60 / 60)
        this.minute = Math.floor(leftTimeStr / 1000 / 60 % 60)
        this.second = Math.floor(leftTimeStr / 1000 % 60)
        this.miSecond = Math.floor(leftTimeStr / 100 % 10).toString()
        this.hour = this.hour < 10 ? '0' + this.hour : this.hour
        this.minute = this.minute < 10 ? '0' + this.minute : this.minute
        this.second = this.second < 10 ? '0' + this.second : this.second
        //动态显示时间
        setTimeout(() => {
          this.countTime()
        }, 100)
      } else {
        if (this.myLotteryData.helpTasks.length < 3) {
          this.progressRemind = '遗憾超时,和大奖擦肩而过'
        }
        if (this.myLotteryData.endDay.split('2020-')[1].split('-')[1] == new Date().getDate()) {
          this.overTimeRemind = '活动已结束'
        } else {
          this.overTimeRemind = '已结束,明天还有一次机会哦'
        }
      }
    },
    lookRule() {
      this.maskShow = true
    },
    closeMask(gzh) {
      this.maskShow = false
      // this.prizeIsPopUp = true
      this.guideShow = false
      this.lotteryShow = false
      this.shareShow = false
      if (gzh) {
        this.gzhAttentionShow = false
      }
    },
    chooseAward(index, prizeId) {
      this.curIndex = index
      this.prizeId = prizeId
    },
    liuyibaShow() {//判断618活动是否在有效期
      this.$http.post('/apicenter/xcx.do?method=getDictNameByVal', {
        data: {
          openid: this.openid || '',
          code: 'FRIENDHELP_CONFIG',
          val: 'homepage_show'
        }
      }).then(res => {
        this.isLYBVaild = res.data
        // if (this.isLYBVaild === 'Y') {
        this.queryPrizeList()
        this.liuyibaUserJoinMsg()
        // }
      })
    },
    queryPrizeList() {//奖品列表
      let activityType = this.activity === 'A' ? 'help' : 'submit_order_help'
      this.$http.post('/personal/lottery/prize/listByActivityNo', {
        data: {
          openid: this.openid || '',
          token: this.loginToken,
          activityNo: activityType //抽奖活动的类型。help：首页助力抽奖活动 submit_order_help：下单后助力活动
        }
      }).then(res => {
        this.prizeList = res.data.prizes
        this.activityId = res.data.activityId
        // this.prizeId = res.data.prizes[0].id
      })
    },
    getMyLotteryDetail() {//邀请人助力详情
      this.$http.post('/personal/lottery/helpLottery/getMyLotteryDetail', {
        data: {
          openid: this.openid || '',
          token: this.loginToken,
          userLotteryId: this.userLotteryId
        }
      }).then(res => {
        this.myLotteryData = res.data
        if (res.data.helpTasks.length == 0) {
          this.progressRemind = '还差3人就要成功了,分享到群成功率高'
        } else if (res.data.helpTasks.length == 1) {
          this.progressRemind = '还差2人就要成功了,分享到群成功率高'
        } else if (res.data.helpTasks.length == 2) {
          this.progressRemind = '还差1人就要成功了'
        } else {
          this.progressRemind = '助力成功,开奖时间为' + res.data.lotteryActivity.lotteryTime
          this.btx = '关注公众号,开奖自动通知'
          this.done = true
        }
        this.countTime()
        this.beginDay = res.data.beginDay.replace(/-/g, '/')
        this.endDay = res.data.endDay.split('2020-')[1].replace(/-/g, '/')
        if (this.myLotteryData.helpTasks.length < 3) {
          let temp = {
            helpUserAvatar: ''
          }
          this.myLotteryData.helpTasks.push(temp)
        }
        if (this.myLotteryData.helpTasks.length < 3) {
          let temp = {
            helpUserAvatar: ''
          }
          this.myLotteryData.helpTasks.push(temp)
        }
        if (this.myLotteryData.helpTasks.length < 3) {
          let temp = {
            helpUserAvatar: ''
          }
          this.myLotteryData.helpTasks.push(temp)
        }
      })
    },
    queryPrizeResult() {//查询抽奖结果
      this.$http.post('/personal/lottery/helpLottery/getResult', {
        data: {
          openid: this.openid || '',
          token: this.loginToken,
          userLotteryId: this.userLotteryId
        }
      }).then(res => {
        if (res.prizes.length > 0) {
          this.couponArr = res.prizes
          this.lotteryShow = true
        }
      })
    },
    choosePrize() {//选择奖品
      this.$http.post('/personal/lottery/helpLottery/choosePrize', {
        data: {
          nickname: this.nickname,
          openid: this.openid || '',
          token: this.loginToken,
          activityId: this.activityId,
          prizeId: this.prizeId,
          avatar: this.avatar,
          mobile: '',
          source: 1 //活动来源默认为0:小程序   1:app   
        }
      }).then(res => {
        this.liuyibaUserJoinMsg()
      })
    },
    popupReport() {//助力活动弹窗上报
      this.$http.post('/personal/lottery/helpLottery/popupReport', {
        data: {
          openid: this.openid || '',
          token: this.loginToken,
          userLotteryId: this.userLotteryId,
          handleFail: true,
          handleError: true
        }
      })
    },
    liuyibaUserJoinMsg() {//用户参与助力活动信息
      let activityType = this.activity === 'A' ? 'help' : 'submit_order_help'
      this.$http.post('/personal/lottery/helpLottery/takePartInfo', {
        data: {
          openid: this.openid || '',
          token: this.loginToken,
          activityNo: activityType
        }
      }).then(res => {
        this.prizeIsPopUp = res.data.popup
        if (res.data.userLotteryId) {
          this.userLotteryId = res.data.userLotteryId
          this.isTakeIn = true
          //存在参与活动的情况下才去查询
          this.getMyLotteryDetail()
          this.queryPrizeResult()
        }
        if (!res.data.popup) {
          // this.popupReport()
        }
      })
    },
    zeroGet() {
      if (!this.prizeId) {
        this.$toast('请选择奖品')
        return
      }
      if (this.loginToken) {
        window._hmt.push(['_trackEvent', "618-duanwu", 'activity_a_invite_get_a_prize'])
        this.choosePrize()
        localStorage.setItem('getUserInfoCallback', true)
      } else {
        this.init()
      }
    },
    openGuideDialog() {
      this.guideShow = this.leftTime > 0 ? true : false
    },
    onShareAppMessage(e) {
      if (this.activity === 'A') {
        window._hmt.push(['_trackEvent', "618-duanwu", 'activity_a_invite_friend_help'])
      } else {
        window._hmt.push(['_trackEvent', "618-duanwu", 'activity_b_a_invite_friend_help'])
      }
      if (this.btx == '关注公众号,开奖自动通知') {
        this.gzhAttentionShow = true
        return
      }
      this.reportShare()
      window.shareSuccess = () => {
        // this.reportShare()
        this.shareShow = true
      }
      window.shareFail = () => { }
      window.shareCancel = () => { }
      let day = new Date().getDate()
      let title = null
      if (day % 3 == 0) {
        title = '邀请你抽' + this.myLotteryData.choosePrize.name + ',中奖率100%,只要点一下哦！'
      } else if (day % 3 == 1) {
        title = '邀请你抽' + this.myLotteryData.choosePrize.name + ',中奖率100%,你也有奖拿！'
      } else if (day % 3 == 2) {
        title = '邀请你抽' + this.myLotteryData.choosePrize.name + ',中奖率100%,感激不尽！'
      }
      let url = '/pages/liuyiba/liuyiba?' + `from=share&userLotteryId=${encodeURIComponent(this.userLotteryId)}&activity=${encodeURIComponent(this.activity)}&openid=${encodeURIComponent(this.openid)}`
      // let url = '/pages/liuyiba/liuyiba?' + `from=share&userLotteryId=${encodeURIComponent(this.userLotteryId)}&activity=${encodeURIComponent(this.activity)}&token=${encodeURIComponent(this.loginToken)}`
      window.kuaidi100.share(JSON.stringify({
        // title: "邀请你参与抽" + this.myLotteryData.choosePrize.name + ",中奖率100%",
        title: title,
        desc: '七月助力',
        url: 'https://m.kuaidi100.com',
        thumbImg: '',
        shareType: 'content',
        miniId: 'gh_a63a83fbf60a',
        path: url,
        shareImg: 'https://cdn.kuaidi100.com/images/m/activity/618/app-share-image.jpg',
        platform: 'WECHAT'
      }))
      console.log(url, 'url')
    },
    dataInit() {
      if (window.kuaidi100) {
        // app回调js
        window.getUserInfoCallback = userInfo => {
          if (typeof userInfo === 'string' && userInfo.indexOf('{') > -1) {
            userInfo = JSON.parse(userInfo)
          }
          if (userInfo.token) {
            this.avatar = userInfo.avatarUrl
            this.openid = userInfo.openid
            this.loginToken = userInfo.token
            this.nickname = userInfo.nickname || ''
            this.liuyibaShow()
            if (!userInfo.avatarUrl) {
              this.getSSOUserInfo()
            }
            this.tokenTransformOpenid()
          }

        }
        // 调用app
        window.kuaidi100.getUserInfo()
      }
    },
    getcookie(cookieName) {
      let cookieValue = ""
      if (document.cookie && document.cookie != '') {
        let cookies = document.cookie.split(';')
        for (let i = 0; i < cookies.length; i++) {
          let cookie = cookies[i].replace(/(^\s*)|(\s*$)/g, "");
          if (cookie.substring(0, cookieName.length + 1) == (cookieName + '=')) {
            cookieValue = unescape(cookie.substring(cookieName.length + 1))
            break
          }
        }
      }
      return cookieValue;
    },
    getCoupon() {
      this.choosePrize()
    },
    init() {
      this.activity = this.$route.query.activity || 'B'
      if (this.activity === 'A') {
        window._hmt.push(['_trackEvent', "618-duanwu", 'activity_a_visit'])
        this.answerTxt = '抽奖'
      } else {
        window._hmt.push(['_trackEvent', "618-duanwu", 'activity_b_visit'])
        this.answerTxt = '免单'
      }

      this.loginToken = this.getToken()

      if (!this.loginToken) {
        window.kuaidi100.h5CallAppLogin()
        this.timer = setInterval(() => {
          this.loginToken = this.getToken()
          if (this.loginToken) {
            this.dataInit()
            clearInterval(this.timer)
          }
        }, 1000)
      } else {
        this.dataInit()
      }
    },
    getToken() {
      let token = ""
      let ua = window.navigator.userAgent.toLowerCase()
      if (/iphone|ipad|ipod|ios/.test(ua)) {
        token = this.getcookie("IOSTOKEN")
      } else {
        token = this.getcookie("TOKEN")
      }
      return token || ""
    },
    getSSOUserInfo() {
      this.$http.post('/user/userapi.do?method=getuserinfo', {
        data: {
          token: this.loginToken
        }
      }).then(res => {
        this.avatar = res.user.avatar
      })
    },
    goToBest() {
      if (this.activity === 'A') {
        window._hmt.push(['_trackEvent', "618-duanwu", 'activity_a_to_use'])
      } else {
        window._hmt.push(['_trackEvent', "618-duanwu", 'activity_b_to_use'])
      }
      if (navigator.userAgent.toLowerCase().indexOf('android') > -1) {
        window.kuaidi100.h5CallAppLogin()
        window.location.href = 'kuaidi100://ilovegirl/near'
      } else {
        window.location.href = 'kuaidi100://ilovegirl/near?selectIndex=2'
      }
    },
    toMyCoupon() {
      if (navigator.userAgent.toLowerCase().indexOf('android') > -1) {
        window.kuaidi100.h5CallAppLogin()
        window.location.href = 'kuaidi100://ilovegirl/coupons'
      } else {
        window.location.href = 'kuaidi100://ilovegirl/coupon?viewController=KDOHCouponController&tag=0&mustLogin=1&handReset=1'
      }
    },
    reportShare() {
      this.$http.post('/personal/lottery/helpLottery/reportShare', {
        data: {
          openid: this.openid,
          userLotteryId: this.userLotteryId
        }
      }).then(res => { })
    },
    copyText() {
      let content = '快递100'
      var aux = document.createElement("input")
      aux.setAttribute("value", content)
      document.body.appendChild(aux)
      aux.select()
      document.execCommand("copy")
      document.body.removeChild(aux)
      this.$toast('复制成功')
    },
    tokenTransformOpenid() {
      this.$http.post('/apicenter/xcx.do?method=queryOpenidByToken', {
        data: {
          token: this.loginToken
        }
      }).then(res => {
        this.openid = res.data
      })
    },
    // ios登录成功回调调用H5方法
    appWebViewDidFinishLoad() {
      this.$toast(window.kuaidi100 + ',appWebViewDidFinishLoad')
      window.getUserInfoCallback = userInfo => {
        if (typeof userInfo === 'string' && userInfo.indexOf('{') > -1) {
          userInfo = JSON.parse(userInfo)
        }
        if (userInfo.token) {
          this.avatar = userInfo.avatarUrl
          this.openid = userInfo.openid
          this.loginToken = userInfo.token
          this.nickname = userInfo.nickname || ''
          this.liuyibaShow()
          if (!userInfo.avatarUrl) {
            this.getSSOUserInfo()
          }
          this.tokenTransformOpenid()
        }
      }
      // 调用app
      window.kuaidi100.getUserInfo()
    }
  },
  mounted() {
    document.title = '快递100幸运0元领'
    this.cHegiht = document.documentElement.clientHeight + 'px'
    window.addEventListener("load", () => {
      this.init()
    }, false)
    window.appWebViewDidFinishLoad = this.appWebViewDidFinishLoad.bind(this)
  }
}